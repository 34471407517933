import { useEffect } from "react";

const GoogleAd = ({ slot, format = "auto", responsive = "true" }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    script.async = true;
    script.crossOrigin = "anonymous";

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    try {
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    } catch (e) {
      console.error("AdSense error:", e);
    }
  }, [typeof window !== "undefined"]);

  return (
    <div className="google-ad">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-5151987250121796"
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={responsive}
      ></ins>
    </div>
  );
};

export default GoogleAd;

// import GoogleAd from "./GoogleAd";

// const MyComponent = () => {
//   return (
//     <div>
//       <h1>My Website</h1>
//       <GoogleAd slot="XXXXXXXXXX" />
//     </div>
//   );
// };

// export default MyComponent;
