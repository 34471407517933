import React, { useContext, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { CognitoIdentityServiceProvider, config } from "aws-sdk";
import { Oval } from "react-loader-spinner";
import { Checkbox, Input, Modal } from "antd";
import { IoMdClose } from "react-icons/io";
import { generateSecretHash } from "../../utils/layoutUtils";
import {
  emailregex,
  handleResendOTPError,
  handleSignupAndConfirmUserError,
  passregex,
} from "../../utils/utils";
import { openNotificationWithIcon } from "../../App";
import { FaCheck } from "react-icons/fa";
import myContext from "../../state/myContext";
import { ReactComponent as Rightmark } from "../../assets/icons/rightmark.svg";
import { Link, useNavigate } from "react-router-dom";

config.update({ region: "ap-south-1" });
const cognito = new CognitoIdentityServiceProvider();

const Signup = ({ setIsSignUp }) => {
  const navigate = useNavigate();
  const { show_login_modal, setShow_login_modal } = useContext(myContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting1, setIsSubmitting1] = useState(false);
  const [otp_modal, setOtp_modal] = useState(false);
  const [success_modal, setSuccess_modal] = useState(false);
  const [terms, setTerms] = useState(true);
  const [errorMess, setErrorMess] = useState("");
  const [form, setForm] = useState({
    email: "",
    password: "",
    fName: "",
    lName: "",
    mName: "",
    cPassword: "",
    otp: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]:
          name == "otp"
            ? value.trim().replace(/[^0-9 ]/g, "")
            : value.trimStart(),
      };
    });
    setErrorMess("");
  };

  // Function to sign up a new user
  const signUpUser = async () => {
    if (
      !form.email ||
      !form.password ||
      !form.cPassword ||
      !form.fName ||
      !form.lName
    ) {
      openNotificationWithIcon("info", "Alert", "Please enter required fields");
      return;
    }
    if (!emailregex.test(form.email)) {
      openNotificationWithIcon("info", "Alert", "Invailid email address");

      return;
    }
    if (!passregex.test(form.password)) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Password must contain a minimum of 8 characters with at least one number"
      );
      return;
    }
    if (form.password !== form.cPassword) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "The password and confirmation password do not match."
      );

      return;
    }
    if (!terms) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Please check terms and condition"
      );

      return;
    }

    setIsSubmitting(true);
    const secretHash = await generateSecretHash(form.email); // Generate the secret hash
    const userAttributes = [
      {
        Name: "family_name",
        Value: form.lName,
      },
      {
        Name: "given_name",
        Value: form.fName,
      },
      {
        Name: "email",
        Value: form.email,
      },
    ];

    // Add middle name attribute if provided
    if (form.mName) {
      userAttributes.push({
        Name: "middle_name",
        Value: form.mName,
      });
    }
    const params = {
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      Username: form.email,
      Password: form.password,
      SecretHash: secretHash, // Include the secret hash
      UserAttributes: userAttributes,
    };

    try {
      await cognito.signUp(params).promise();

      setIsSubmitting(false);
      setOtp_modal(true);
      setShow_login_modal(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Sign up successful. Check your email for the verification code."
      );
    } catch (error) {
      handleSignupAndConfirmUserError(error);
      setIsSubmitting(false);
    }
  };

  // Function to confirm user sign-up
  const confirmUserSignUp = async () => {
    if (!form.otp) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Please enter Confirmation Code"
      );
      return;
    }
    setIsSubmitting1(true);
    const secretHash = await generateSecretHash(form.email); // Generate the secret hash
    const params = {
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      ConfirmationCode: form.otp,
      Username: form.email,
      SecretHash: secretHash, // Include the secret hash
    };

    try {
      await cognito.confirmSignUp(params).promise();

      setIsSubmitting1(false);
      setSuccess_modal(true);
      setOtp_modal(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "User confirmation successful. You can now log in."
      );
    } catch (error) {
      // handleSignupAndConfirmUserError(error);
      setErrorMess(handleSignupAndConfirmUserError(error));
      setIsSubmitting1(false);
    }
  };

  // Function to resend OTP
  const resendConfirmationCode = async () => {
    const secretHash = await generateSecretHash(form.email); // Generate the secret hash
    const params = {
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      Username: form.email,
      SecretHash: secretHash, // Include the secret hash
    };

    try {
      await cognito.resendConfirmationCode(params).promise();

      openNotificationWithIcon(
        "success",
        "Success",
        "Confirmation code resent successfully. Check your email for the new code."
      );
    } catch (error) {
      handleResendOTPError(error);
    }
  };
  return (
    <>
      <CustomInput
        className={"mt-8"}
        placeholder="Enter here"
        label={"First Name *"}
        value={form.fName}
        name={"fName"}
        onChange={handleChange}
        type="text"
      />
      <CustomInput
        placeholder="Enter here"
        label={"Last Name *"}
        value={form.lName}
        name={"lName"}
        onChange={handleChange}
        type="text"
      />
      <CustomInput
        placeholder="Enter here"
        label={"Middle Name"}
        value={form.mName}
        name={"mName"}
        onChange={handleChange}
        type="text"
      />
      <CustomInput
        placeholder="Enter here"
        label={"Email ID *"}
        value={form.email}
        name={"email"}
        onChange={handleChange}
        type="email"
      />
      <CustomInput
        isPassword
        placeholder="Enter here"
        label={"Password *"}
        value={form.password}
        name={"password"}
        onChange={handleChange}
        type="password"
      />
      <CustomInput
        isPassword
        placeholder="Enter here"
        label={"Confirm Password *"}
        value={form.cPassword}
        name={"cPassword"}
        onChange={handleChange}
        type="password"
      />
      <div className="mt-6" />

      <div className="flex  gap-2 mb-4">
        <div
          onClick={() => setTerms((pre) => !pre)}
          className={`border-[#CD8E42] mt-[2px] flex items-center justify-center h-[20px] w-[20px] rounded-[5px] cursor-pointer border ${
            terms ? "bg-[#FABF55]" : "bg-[#FFF]"
          } `}
        >
          {terms && <FaCheck color={"#FFF"} size={12} />}
        </div>
        <div className="flex flex-wrap  gap-2">
          <span
            className="font-normal text-[#FABF55] cursor-pointer"
            onClick={() => {
              navigate("/terms-condition");
              setShow_login_modal(false);
            }}
          >
            Terms & Conditions
          </span>

          <span className="font-normal text-[#FFF]">&</span>

          <span
            className="font-normal text-[#FABF55] cursor-pointer"
            onClick={() => {
              navigate("/privacy-policy");
              setShow_login_modal(false);
            }}
          >
            Privacy Policy
          </span>

          <span className="font-normal text-[#FFF]">&</span>

          <span
            className="font-normal text-[#FABF55] cursor-pointer"
            onClick={() => {
              navigate("/refund-policy");
              setShow_login_modal(false);
            }}
          >
            Refund Policy
          </span>
        </div>
      </div>

      <button
        onClick={signUpUser}
        disabled={isSubmitting}
        className={`flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55]`}
      >
        {isSubmitting ? (
          <>
            <Oval
              visible={true}
              height="30"
              width="30"
              color="#FF9500"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              secondaryColor="#FF950055"
              wrapperClass=""
              strokeWidth={6}
            />
          </>
        ) : (
          "Submit"
        )}
      </button>
      <p className="text-center font-inter text-[#FFFFFF] text-clamp-sm mt-8">
        <span
          className="text-[#FCD36D] cursor-pointer"
          onClick={() => {
            setForm({
              email: "",
              password: "",
              fName: "",
              lName: "",
              mName: "",
              cPassword: "",
            });
            setIsSignUp(true);
          }}
        >
          Sign In
        </span>{" "}
        if you have already an account
      </p>

      <Modal
        width={window.innerWidth < 400 ? "90%" : "490px"}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
            borderRadius: 20,
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={otp_modal}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="flex flex-col py-1 px-3 md:py-4 md:px-6">
          <div className="flex  items-center">
            <div className="font-baskerville text-clamp-popup-title text-[#fff] flex-1">
              OTP Verification
            </div>
            <IoMdClose
              size={30}
              color="#fff"
              onClick={() => setOtp_modal(false)}
              className="cursor-pointer"
            />
          </div>
          <p className="text-center text-clamp-base18 text-[#fff] font-inter font-normal mt-3">
            Please check your email
          </p>
          <h1 className="text-center text-clamp-base16 text-[#fff] font-inter font-normal mt-1">
            We've sent a code to {form.email}
          </h1>

          <Input
            maxLength={6}
            max={6}
            placeholder={"Enter OTP"}
            type="text"
            value={form.otp}
            name={"otp"}
            onChange={handleChange}
            className="mt-4 text-lg py-2 px-4"
          />
          {errorMess && (
            <p className="bg-[#FF0000] font-inter mt-2 py-2 px-3 text-[#fff] flex">
              {errorMess}
            </p>
          )}
          <p className="text-center text-[#FFFFFF] text-clamp-sm mt-4 md:mt-8">
            Didn't get the code?{" "}
            <span
              className="text-[#FCD36D] cursor-pointer font-medium"
              onClick={() => resendConfirmationCode()}
            >
              Click to Resend
            </span>
          </p>
          <p className="text-center text-[#FFFFFF] text-clamp-sm mt-2 ">
            If you don’t see the email in your inbox, please check your SPAM or
            JUNK folder. Mark the email as 'Not Spam' to ensure future emails
            are delivered to your inbox.
          </p>
          <button
            onClick={confirmUserSignUp}
            disabled={isSubmitting1}
            className={`mt-6 flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55]`}
          >
            {isSubmitting1 ? (
              <>
                <Oval
                  visible={true}
                  height="30"
                  width="30"
                  color="#FF9500"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  secondaryColor="#FF950055"
                  wrapperClass=""
                  strokeWidth={6}
                />
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>
      <Modal
        width={window.innerWidth < 400 ? "90%" : "490px"}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
            borderRadius: 20,
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={success_modal}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="flex flex-col py-1 px-3 md:py-2 md:px-4">
          <div className="flex  items-center">
            <div className="font-baskerville text-clamp-popup-title text-[#fff] flex-1">
              Alert
            </div>
            <IoMdClose
              size={30}
              color="#fff"
              onClick={() => setSuccess_modal(false)}
              className="cursor-pointer"
            />
          </div>
          <Rightmark className="h-[50px] md:h-[80px] w-[50px] md:w-[80px] self-center" />
          <p className="text-center text-clamp-base18 text-[#fff] font-inter font-normal mt-3">
            Account has been created sucessfuly
          </p>

          <button
            onClick={() => {
              setIsSignUp(true);
              setShow_login_modal(true);
              setSuccess_modal(false);
            }}
            className={`mt-6 flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55]`}
          >
            Go back to Login
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Signup;
