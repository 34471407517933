import React, { useContext, useEffect, useState } from "react";
import { generateSecretHash } from "../../utils/layoutUtils";
import { CognitoIdentityServiceProvider, config } from "aws-sdk";
import myContext from "../../state/myContext";
import { Modal } from "antd";
import { IoMdClose } from "react-icons/io";
import { Oval } from "react-loader-spinner";
import { handleLoginError } from "../../utils/utils";
import CustomInput from "../../components/CustomInput";
import ForgetPassword from "./ForgetPassword";
import Signup from "./Signup";
import { openNotificationWithIcon } from "../../App";
import { get_user_profile } from "../../utils/apis";
import { gtag } from "../../utils/gtag";
import youtube from "../../assets/svgimg/youtube.svg";
import useStore from "../../state/myStore";

// import { Amplify } from "aws-amplify";
// import { signInWithRedirect } from "@aws-amplify/auth"; // Importing correct method
// import awsconfig from "../../aws-exports"; // Ensure the correct path to aws-exports.js

// Configure Amplify
// Amplify.configure(awsconfig);

config.update({ region: "ap-south-1" });

const cognito = new CognitoIdentityServiceProvider();

const Login = () => {
  const { getUserProfile } = useStore();
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    show_login_modal,
    setShow_login_modal,
    show_login_first,
    setShow_login_first,
  } = useContext(myContext);
  const [for_pass, setFor_pass] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // Function to log in a user
  const loginUser = async () => {
    if (!form.email || !form.password) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Please enter username or password."
      );
      return;
    }

    gtag("set", "user_properties", {
      email: form.email,
      // password: form.password,
    });

    setSubmitting(true);
    const secretHash = await generateSecretHash(form.email);
    const params = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      AuthParameters: {
        USERNAME: form.email,
        PASSWORD: form.password,
        SECRET_HASH: secretHash,
      },
    };

    try {
      const response = await cognito.initiateAuth(params).promise();
      openNotificationWithIcon("success", "Success", "Login successful!");
      localStorage.setItem(
        "token",
        JSON.stringify(response.AuthenticationResult)
      );
      const expirationTime =
        Date.now() + response.AuthenticationResult.ExpiresIn * 1000; // Current time + expiresIn in milliseconds
      localStorage.setItem("tokenExpiration", expirationTime);
      // getUserProfile();
      get_user_profile();
      setShow_login_modal(false);
      setSubmitting(false);
    } catch (error) {
      handleLoginError(error);
      setSubmitting(false);
    }
  };

  // Function to exchange authorization code for tokens using /oauth2/token
  const exchangeCodeForTokens = async (code) => {
    const domain = process.env.REACT_APP_COGNITO_DOMAIN;
    const clientId = process.env.REACT_APP_AWS_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_AWS_APP_CLIENT_SECRET;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;

    const body = new URLSearchParams({
      grant_type: "authorization_code",
      client_id: clientId,
      code: code,
      redirect_uri: redirectUri,
      client_secret: clientSecret,
    });

    try {
      const response = await fetch(`https://${domain}/oauth2/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body.toString(),
      });

      const data = await response.json();

      if (response.ok) {
        openNotificationWithIcon("success", "Success", "Login successful!");
        // console.log('Tokens:', data);
        // Map the keys to the desired format
        const formattedData = {
          AccessToken: data.access_token,
          IdToken: data.id_token,
          RefreshToken: data.refresh_token,
          ExpiresIn: data.expires_in,
          TokenType: data.token_type,
        };
        localStorage.setItem("token", JSON.stringify(formattedData));
        const expirationTime = Date.now() + formattedData.ExpiresIn * 1000; // Current time + expiresIn in milliseconds
        localStorage.setItem("tokenExpiration", expirationTime);
        setShow_login_modal(false);
        setSubmitting(false);
        get_user_profile();
      } else {
        openNotificationWithIcon(
          "error",
          "Login Failed",
          "Token exchange failed."
        );
        setSubmitting(false);
      }
    } catch (error) {
      handleLoginError(error);
      setSubmitting(false);
    }
  };

  // Detect authorization code in URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      exchangeCodeForTokens(code);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);
  useEffect(() => {
    setIsSignUp(false);
    setFor_pass(false);
  }, [show_login_modal]);

  useEffect(() => {
    setForm({
      ...form,
      email: "",
      password: "",
    });
  }, [show_login_modal]);

  const handleCancel = () => {
    setShow_login_modal(false);
  };

  const signInWithGoogle = () => {
    const domain = process.env.REACT_APP_COGNITO_DOMAIN; // e.g., yourapp.auth.ap-south-1.amazoncognito.com
    const clientId = process.env.REACT_APP_AWS_APP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI; // e.g., https://yourapp.com/
    const responseType = "code";
    const scope =
      "aws.cognito.signin.user.admin%20email%20openid%20phone%20profile";
    const state = encodeURIComponent(
      window.location.pathname + window.location.search
    );

    const googleLoginUrl = `https://${domain}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${state}`;

    window.location.href = googleLoginUrl;
  };

  // const signInWithFacebook = () => {
  //   const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  //   const clientId = process.env.REACT_APP_AWS_APP_CLIENT_ID;
  //   const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  //   const responseType = "code";
  //   const scope =
  //     "aws.cognito.signin.user.admin%20email%20openid%20phone%20profile";

  //   const facebookLoginUrl = `https://${domain}/oauth2/authorize?identity_provider=Facebook&redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}&scope=${scope}`;

  //   window.location.href = facebookLoginUrl;
  // };
  return (
    <>
      <Modal
        width={window.innerWidth < 400 ? "90%" : "490px"}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
            borderRadius: 20,
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={show_login_modal}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="flex flex-col py-1 px-3 md:py-4 md:px-6">
          <div className="flex items-center">
            <div className="flex-1 justify-center items-center flex">
              {for_pass || (
                <div className="border-[#CD8E42] flex overflow-hidden ">
                  <button
                    className={`px-2 sx:px-4 md:px-8 py-2 font-inter font-medium bg-[transparent] h-[50px] text-2xl ${
                      show_login_first
                        ? "border-b-[#CD8E42] text-[#FFF] border-b-4"
                        : "border-b-[#FFFFFF30] text-[#FFFFFF30] border-b-2"
                    }`}
                    onClick={() => {
                      setForm({
                        email: "",
                        password: "",
                      });
                      setShow_login_first(true);
                    }}
                  >
                    Sign In
                  </button>
                  <button
                    className={`px-2 sx:px-4 md:px-8 py-2 font-inter font-medium bg-[transparent] h-[50px] text-2xl ${
                      !show_login_first
                        ? "border-b-[#CD8E42] text-[#FFF] border-b-4"
                        : "border-b-[#FFFFFF30] text-[#FFFFFF30] border-b-2"
                    }`}
                    onClick={() => {
                      setForm({
                        email: "",
                        password: "",
                      });
                      setShow_login_first(false);
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              )}
              {for_pass && (
                <p className="font-inter font-medium text-2xl text-[#fff] flex-1 flex items-center justify-center gap-2">
                  Forgot Password
                </p>
              )}
            </div>

            <IoMdClose
              size={30}
              color="#fff"
              onClick={handleCancel}
              className="cursor-pointer"
            />
          </div>
          {!show_login_first && <Signup setIsSignUp={setShow_login_first} />}

          {for_pass && (
            <ForgetPassword
              setFor_pass={setFor_pass}
              setIsSignUp={setIsSignUp}
            />
          )}
          {show_login_first && !for_pass && (
            <>
              <button
                onClick={signInWithGoogle}
                className={`mt-8 flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55] mb-2`}
              >
                <img
                  src="https://d3jsl6t25m91u2.cloudfront.net/default-assets/icon/cognito-icon-idp-google-light.svg"
                  alt="Google Icon"
                  className="h-5 w-5 mr-2"
                />
                Sign in with Google
              </button>
              {/* <button
                onClick={signInWithFacebook}
                className={`flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55] mb-2`}
              >
                <img
                  src="https://d3jsl6t25m91u2.cloudfront.net/default-assets/icon/cognito-icon-idp-facebook-light.svg"
                  alt="Facebook Icon"
                  className="h-5 w-5 mr-2"
                />
                Continue with Facebook
              </button> */}
              <p className="text-[#FCD36D] font-inter font-medium text-clamp-sm flex underline mx-auto">
                OR
              </p>
              <CustomInput
                placeholder="Enter here"
                label={"Email ID*"}
                value={form.email}
                name={"email"}
                onChange={handleChange}
                type="email"
              />
              <CustomInput
                isPassword
                className={"mt-4"}
                placeholder="Enter here"
                label={"Password*"}
                type="password"
                value={form.password}
                onChange={handleChange}
                name={"password"}
              />
              <div className="flex my-4 justify-end">
                <p
                  className="text-[#FCD36D] font-inter font-medium text-clamp-sm cursor-pointer flex underline"
                  onClick={() => setFor_pass(true)}
                >
                  Forgot password?
                </p>
              </div>

              <button
                onClick={loginUser}
                disabled={isSubmitting}
                className={`flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55] mb-2`}
              >
                {isSubmitting ? (
                  <>
                    <Oval
                      visible={true}
                      height="30"
                      width="30"
                      color="#FF9500"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      secondaryColor="#FF950055"
                      wrapperClass=""
                      strokeWidth={6}
                    />
                  </>
                ) : (
                  "Sign In"
                )}
              </button>

              {/* <div
                className="flex my-4 self-center items-center gap-2 cursor-pointer"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=EdZhZx5KWx0",
                    "_blank"
                  )
                }
              >
                <img
                  src={youtube}
                  className="h-[20px] w-[20px] object-contain"
                />
                <p className="text-[#FCD36D] font-inter font-medium text-clamp-sm cursor-pointer flex underline">
                  How to sign up and other Help?
                </p>
              </div> */}
              {/* <p className="text-center font-inter text-[#FFFFFF] text-clamp-sm mt-4 md:mt-8">
                <span
                  className="text-[#FCD36D] cursor-pointer font-medium"
                  onClick={() => {
                    setForm({
                      email: "",
                      password: "",
                    });
                    setIsSignUp(true);
                  }}
                >
                  Sign Up
                </span>{" "}
                if they don't have an account
              </p> */}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Login;
