import React from "react";
import GoogleAd from "../components/GoogleAd";

const CheckAd = () => {
  return (
    <div>
      <p>Google AdSense</p>
      <GoogleAd slot="1832948690" />
    </div>
  );
};

export default CheckAd;
