import axios from "axios";
import { create } from "zustand";
import { isTokenExpired } from "../utils/utils";

const useStore = create((set) => ({
  userData: {},
  isLoggedIn: false,

  categoryData: [],
  cat_loading: false,

  selected_category: "",

  getUserProfile: async () => {
    if (isTokenExpired()) {
      set({ userData: {} });
      return;
    }

    const user = JSON.parse(localStorage.getItem("token"));
    const config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "get-user-subscription",
      headers: {
        Auth_ID_Token: user?.IdToken,
        Access_Token: user?.AccessToken,
      },
    };

    try {
      const response = await axios.request(config);
      console.log("response", response);

      if (response.data.statusCode == 200) {
        set({ userData: response.data.body });
        set({ isLoggedIn: true });
      } else {
        set({ userData: {} });
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  },

  getCategory: async () => {
    set({ cat_loading: true });
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "list-categories"
      );
      if (response.data.statusCode == 200) {
        set({ categoryData: response.data.body });
        set({ selected_category: response.data?.body?.Wedding[0] });
      } else {
        // set({ categoryData: {} });
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      set({ cat_loading: false });
    }
  },

  setLoggedIn: (status) => {
    set({ isLoggedIn: status });
  },

  clearUserData: () => {
    set({ userData: {} });
    set({ isLoggedIn: false });
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    window.location.reload();
  },
}));

export default useStore;
